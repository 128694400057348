import { useCallback } from 'react';
import { useGlobalContext } from 'context/globalContext';
import { useNavigate } from 'react-router-dom';
import constants from 'utils/constants';
import apiService from '../services/apiService';

const useApi = () => {

  const { state = null } = useGlobalContext();
  const token = (state?.auth?.user?.token) ?? '7502df431159e128e6a61bf05ed7e9b82557146c';
  const navigate = useNavigate();
  const { dispatch } = useGlobalContext();

  const removeSession = async () => {
    try {
      await localStorage.removeItem(constants.user.session);
    } catch (e) {}
  };

  const closeSession = async () => {
    await removeSession();
    dispatch({
      type: 'LOGOUT',
      payload: null,
    });
    navigate('/login', { replace: true });
  };

  const sendRequest = useCallback(async ({ method, url, data = null, onSuccess, onError, onFinish, closeOnError = true }) => {
    try {
      const headers = {
        Authorization: `Token ${token}`,
      };

      const response = await apiService({ method, url, data, headers });
      if (onSuccess) {
        onSuccess(response.data);
      }
    } catch (error) {
      if (error?.response) {
        if (error?.response?.status === 500) {
        } else if (onError) {
          if (closeOnError && (error.response.status === 401 || error.response.status === 403)) {
            await closeSession();
          } else {
            onError(error);
          }
        }
      } else {

      }
    } finally {
      if (onFinish) {
        onFinish();
      }
    }
  }, []);

  return { sendRequest };
};

export default useApi;
