import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import SidebarContent from './sidebar';
import Topbar from './topbar';

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggleSidebar = () => setIsOpen(!isOpen);

  return (
    <Box minH='100vh' bg='gray.100' display='flex'>
      <SidebarContent onClose={() => setIsOpen(false)} isOpen={isOpen} />
      <Box flex='1' ml={{ base: 0, lg: isOpen ? 250 : 0 }} transition='margin 0.3s'>
        <Topbar onOpen={toggleSidebar} />
        <Box p='4'>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
