import React from 'react';
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import GeneralPublic from 'pages/Period/GeneralPublic';
import InvoicesUser from './InvoicesUser';

const Period = () => {

  const { id } = useParams();

  return (
    <Box width='full' overflowX='auto'>
      <Text fontSize='3xl' mb={4} color='blue.800' textAlign='left' fontWeight='bold'>Período 2024</Text>
      <Tabs variant='enclosed' colorScheme='blue'>
        <TabList>
          <Tab _selected={{ color: 'blue.800', bg: 'white' }} color='gray.600' bg='gray.200'>Facturas Usuario</Tab>
          <Tab _selected={{ color: 'blue.800', bg: 'white' }} color='gray.600' bg='gray.200'>Factura Público en General</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <InvoicesUser period={id} />
          </TabPanel>
          <TabPanel>
            <GeneralPublic period={id} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Period;
