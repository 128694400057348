import React, { createContext, useContext, useReducer } from 'react';
import { authInitialState, authReducer } from './authReducer';
import { establishmentInitialState, establishmentReducer } from './establishmentReducer';
import { combineReducers } from './combineReducers';

const GlobalContext = createContext({});

const rootReducer = combineReducers({
  auth: authReducer,
  establishment: establishmentReducer,
});

const initialState = {
  auth: authInitialState,
  establishment: establishmentInitialState,
};

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, initialState);

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
