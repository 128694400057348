import React from 'react';
import { Box, Grid, GridItem } from '@chakra-ui/react';
import MainSettingsForm from './MainSettingsForm';
import CertificateUploadForm from './CertificateUploadForm';
import TicketInfoForm from './TicketInfoForm';

const SettingsPage = () => {
  return (
    <Box p={5}>
      <Grid
        templateColumns={{ base: '1fr', md: '1fr 1fr' }} // Adjusts layout based on the viewport
        gap={6}
      >
        <GridItem>
          <MainSettingsForm />
        </GridItem>
        <GridItem>
          <CertificateUploadForm />
          <Box mt={4}>
            <TicketInfoForm />
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default SettingsPage;
