import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, FormControl, FormLabel, Textarea, useToast, VStack, Heading, Text, Spinner } from '@chakra-ui/react';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';
import { useEstablishmentsManagement, useGetPropertiesForm } from 'hooks/index';
import { useGlobalContext } from 'context/globalContext';
import { getFormData, isEmptyObject } from 'utils/funGeneral';

const validationSchema = Yup.object({
  description: Yup.string().required('La descripción es obligatoria'),
  url: Yup.mixed().required('La imagen es obligatoria'),
});

const TicketInfoForm = () => {
  const toast = useToast();
  const establishmentHelpFormRef = useRef(null);
  const [filePreview, setFilePreview] = useState(null);
  const { isLoading, getEstablishmentTicketHelp, updateEstablishmentTicketHelp, saveEstablishmentTicketHelp, establishmentTicketHelp } = useEstablishmentsManagement(establishmentHelpFormRef);
  const { state: { establishment: { establishmentSelected } } } = useGlobalContext();
  const { setFieldForm, resetEstablishment, getValuesInitial, setClearTouchableInitial } = useGetPropertiesForm();
  const [valuesInitial] = useState({
    description: '',
    url: '',
  });

  const handleFileRemove = () => {
    setFilePreview(undefined); // Limpiar el archivo seleccionado
    const fileInput = document.getElementById('url');
    if (fileInput) {
      fileInput.value = '';
    }
  };

  const setupFormTicket = () => {
    setFieldForm('description', establishmentTicketHelp?.description, establishmentHelpFormRef);
    if (establishmentTicketHelp.url) {
      setFilePreview(establishmentTicketHelp.url);
    }
  };

  const clearFormTicketInfo = () => {
    //Se resetean los nuevos valores
    resetEstablishment(establishmentHelpFormRef);
    handleFileRemove();
  };

  useEffect(() => {
    if (establishmentSelected) {
      getEstablishmentTicketHelp(establishmentSelected?.id);
    }
  }, [establishmentSelected]);

  useEffect(() => {
    if (establishmentSelected) {
      clearFormTicketInfo();
      if (establishmentTicketHelp !== null && !isEmptyObject(establishmentTicketHelp)) {
        setupFormTicket(establishmentTicketHelp);
      } else {
        getValuesInitial(valuesInitial, establishmentHelpFormRef, setClearTouchableInitial);
      }
    }
  }, [establishmentSelected, establishmentTicketHelp]);

  return (
    <Box bg='white' p={4} borderRadius='lg' boxShadow='sm' color='blue.800'>
      <Heading size='lg' mb={6}>Información del Ticket</Heading>
      <Formik
        innerRef={establishmentHelpFormRef}
        initialValues={valuesInitial}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          if (isEmptyObject(establishmentTicketHelp)) { // se guradan los datos
            const data = { ...values, establishment: establishmentSelected?.id };
            const formDataTicket = await getFormData(data);
            await saveEstablishmentTicketHelp(
              formDataTicket,
              response => {
                resetForm();
                handleFileRemove();
                setupFormTicket(response);
                toast({
                  title: 'Información guardada',
                  description: 'La información del ticket ha sido guardada correctamente.',
                  status: 'success',
                  duration: 5000,
                  isClosable: true,
                });
                setSubmitting(false);
              },
            );
          } else {
            const formDataTicket = await getFormData(values);
            await updateEstablishmentTicketHelp(
              establishmentSelected?.id,
              formDataTicket,
              response => {
                resetForm();
                handleFileRemove();
                setupFormTicket(response);
                toast({
                  title: 'Información actualizada',
                  description: 'La información del ticket ha sido actualizada correctamente.',
                  status: 'success',
                  duration: 5000,
                  isClosable: true,
                });
                setSubmitting(false);
              },
            );
          }
        }}
      >
        {({ handleSubmit, setFieldValue, isSubmitting, handleChange, handleBlur }) => (
          <Form>
            {isLoading ? (
              <Spinner size='lg' color='blue.800' />
            ) : (
              <>
                <VStack spacing={4}>
                  <Field name='description'>
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.description && form.touched.description}>
                        <FormLabel>Descripción</FormLabel>
                        <Field
                          as={Textarea}
                          id='description'
                          name='description'
                          placeholder='Descripción de donde encontrar el número de recibo en el ticket'
                          onBlur={handleBlur('description')}
                          onChange={handleChange('description')}
                        />
                        <ErrorMessage component='div' name='description' style={{ color: 'red', fontSize: 'sm' }} />
                      </FormControl>
                    )}
                  </Field>
                  <Field name='image'>
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.url && form.touched.url}>
                        <FormLabel>Imagen</FormLabel>
                        <input
                          id='url'
                          name='url'
                          type='file'
                          onBlur={handleBlur('url')}
                          onChange={event => {
                            const file = event.target.files[0];
                            if (file) {
                              setFieldValue('url', file);
                              const reader = new FileReader();
                              reader.onloadend = () => {
                                setFilePreview(reader.result);
                              };
                              reader.readAsDataURL(file);
                            } else {
                              setFilePreview(null);
                            }
                          }}
                          accept='image/*'
                        />
                        <ErrorMessage component='div' name='url' style={{ color: 'red', fontSize: 'sm' }} />
                      </FormControl>
                    )}
                  </Field>
                  {filePreview && (
                    <>
                      <img src={filePreview} alt='preview' style={{ maxHeight: '200px' }} />
                      <button type='button' onClick={handleFileRemove}>
                        <Text textDecorationLine='underline' fontWeight='medium' fontSize='small' color='blue.500'>Quitar archivo</Text>
                      </button>
                    </>
                  )}
                  <Button onClick={handleSubmit} mt={4} colorScheme='blue' isLoading={isSubmitting} type='submit' width='full'>
                    Guardar Información
                  </Button>
                </VStack>
              </>
            )}

          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default TicketInfoForm;
