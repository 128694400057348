import React, { useCallback, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import useInvoice from 'hooks/useInvoice';
import { useParams } from 'react-router-dom';
import InvoiceDisplay from './InvoiceDisplay';

const InvoiceDetails = () => {
  const { id } = useParams();
  const { retrieveInvoice, invoice } = useInvoice();

  const handleRetrieveInvoice = () => retrieveInvoice(id);

  useEffect(() => {
    handleRetrieveInvoice();
  }, []);

  return (
    <Box>
      {
        invoice ? (
          <InvoiceDisplay invoice={invoice} id={id} handleRetrieveInvoice={handleRetrieveInvoice} />
        ) : null
      }
    </Box>
  );
};

export default InvoiceDetails;
