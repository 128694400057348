import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const InvoiceButton = ({ id }) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(`/admin/facturas/${id}`);
  };

  return (
    <IconButton
      icon={<FontAwesomeIcon icon={faFileInvoice} />}
      colorScheme='green'
      aria-label='Ver Detalle Factura'
      onClick={handleNavigation}
    />
  );
};

export default InvoiceButton;
