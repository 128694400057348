import React, { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, FormControl, FormLabel, Input, Button, Text } from '@chakra-ui/react';
import useInvoice from 'hooks/useInvoice';

const CancelModal = ({ isOpen, onClose, id, handleRetrieveInvoice }) => {
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const { deleteInvoice, isLoading } = useInvoice();

  const onSuccess = response => {
    setSuccessMessage('Factura cancelada.');
    setPassword('');
    handleRetrieveInvoice();
  };

  const onError = error => {
    let message = 'Ocurrió un error desconocido.';
    if (error.response && error.response.data) {
      const { data } = error.response;
      if (typeof data === 'object') {
        if (data.password && Array.isArray(data.password) && data.password.length > 0) {
          message = 'Contraseña incorrecta.';
        } else if (typeof data.message === 'string') {
          message = data.message;
        }
      }
    }
    setErrorMessage(message);
  };

  const handleCancel = () => {
    setErrorMessage('');
    setSuccessMessage('');
    deleteInvoice(id, { password }, onSuccess, onError);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color='blue.800'>Cancelar Comprobante</ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel color='blue.800'>Contraseña</FormLabel>
            <Input
              type='password'
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder='Ingresa tu contraseña'
              color='blue.800'
            />
          </FormControl>
          {errorMessage && <Text color='red.500' mt={2}>{errorMessage}</Text>}
          {successMessage && <Text color='green.500' mt={2}>{successMessage}</Text>}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme='red' onClick={handleCancel} mr={2} isLoading={isLoading}>
            Cancelar
          </Button>
          <Button variant='ghost' onClick={onClose} disabled={isLoading}>
            Cerrar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CancelModal;
