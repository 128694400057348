import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';

const StatusIndicator = ({ status }) => {
  if (parseInt(status) === 2) {
    return (
      <div style={{ color: 'red', display: 'flex', alignItems: 'center' }}>
        <FontAwesomeIcon icon={faLock} />
        <span style={{ marginLeft: 5 }}>Cerrado</span>
      </div>
    );
  }

  return (
    <div style={{ color: 'green', display: 'flex', alignItems: 'center' }}>
      <FontAwesomeIcon icon={faLockOpen} />
      <span style={{ marginLeft: 5 }}>Abierto</span>
    </div>
  );

};

export default StatusIndicator;
