import { useState } from 'react';
import useApi from 'hooks/useApi';
import { useToast } from '@chakra-ui/react';

const useEstablishmentsManagement = nameRef => {
  const [isLoading, setIsLoading] = useState(false);
  const [establishments, setEstablishments] = useState([]);
  const [establishmentTicketHelp, setEstablishmentTicketHelp] = useState({});
  const [establishmentBillingInfo, setEstablishmentBillingInfo] = useState({});

  const toast = useToast();
  const { sendRequest } = useApi();

  const hideLoadingButton = () => {
    if (nameRef) {
      nameRef?.current?.setSubmitting(false);
    }
  };

  const showToast = (title, message) => {
    toast({
      title,
      description: message,
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  };

  const getEstablishmentsManagement = async () => {
    setIsLoading(true);
    sendRequest({
      method: 'get',
      url: '/management/users/establishments',
      onSuccess: data => setEstablishments(data),
      onError: error => {
        showToast(
          'Error al obtener la información',
          error?.data?.message || 'No se pudo obtener los esablecimiento',
        );
      },
      onFinish: () => setIsLoading(false),
    });
  };

  const getEstablishmentBillingInformation = async (id, onSuccess) => {
    setIsLoading(true);
    sendRequest({
      method: 'get',
      url: `management/establishments/${id}/billing_info`,
      onSuccess: data => {
        if (onSuccess) {
          onSuccess(data);
        }
        setEstablishmentBillingInfo(data);
        setIsLoading(false);
      },
      onError: error => {
        showToast('Error al obtener la información', error?.data?.message || 'Error al obtener la ayuda de la facturación');
        setIsLoading(false);
      },
      onFinish: () => setIsLoading(false),
    });
  };

  const saveEstablishmentBillingInformation = async (data, onSuccess) => {
    setIsLoading(true);
    await sendRequest({
      method: 'post',
      url: '/management/billing_info/',
      data,
      onSuccess: data => {
        onSuccess(data);
        setEstablishmentBillingInfo(data);
        setIsLoading(false);
      },
      onError: error => {
        showToast(
          'Error al guardar la información',
          error?.data?.message || 'Ocurrió un error al guardar la información del establecimiento',
        );
        setIsLoading(false);
      },
      onFinish: () => {
        setIsLoading(false);
        hideLoadingButton();
      },
    });
  };

  const updateEstablishmentBillingInformation = async (id, data, onSuccess) => {
    setIsLoading(true);
    await sendRequest({
      method: 'patch',
      url: `/management/billing_info/${id}/`,
      data,
      onSuccess: data => {
        onSuccess(data);
        setEstablishmentBillingInfo(data);
        setIsLoading(false);
      },
      onError: error => {
        showToast(
          'Error al actualizar la información',
          error?.data?.message || 'Ocurrió un error al actualizar la información del establecimiento',
        );
        setIsLoading(false);
      },
      onFinish: () => {
        setIsLoading(false);
        hideLoadingButton();
      },
    });
  };

  const getEstablishmentTicketHelp = async (id, onSuccess) => {
    setIsLoading(true);
    sendRequest({
      method: 'get',
      url: `/management/establishments/${id}/ticket_help`,
      onSuccess: data => {
        if (onSuccess) {
          onSuccess(data);
        }
        setEstablishmentTicketHelp(data);
        setIsLoading(false);
      },
      onError: error => {
        showToast(
          'Error al obtener la información',
          error?.data?.message || 'No se pudo obtener la información',
        );
        setIsLoading(false);
      },
      onFinish: () => setIsLoading(false),
    });
  };

  const saveEstablishmentTicketHelp = async (data, onSuccess) => {
    setIsLoading(true);
    await sendRequest({
      method: 'post',
      url: '/management/ticket_help/',
      data,
      onSuccess: data => {
        onSuccess(data);
        setEstablishmentTicketHelp(data);
        setIsLoading(false);
      },
      onError: error => {
        showToast(
          'Error al guardar',
          error?.data?.message || 'Error al guardar la ayuda de ticket',
        );
        setIsLoading(false);
        hideLoadingButton();
      },
      onFinish: () => {
        setIsLoading(false);
        hideLoadingButton();
      },
    });
  };

  const updateEstablishmentTicketHelp = async (id, data, onSuccess) => {
    setIsLoading(true);
    await sendRequest({
      method: 'patch',
      url: `/management/ticket_help/${id}/`,
      data,
      onSuccess: data => {
        onSuccess(data);
        setEstablishmentTicketHelp(data);
        setIsLoading(false);
      },
      onError: error => {
        showToast(
          'Error al actualizar',
          error?.data?.message || 'Error al actualizar la ayuda de ticket',
        );
        setIsLoading(false);
        hideLoadingButton();
      },
      onFinish: () => {
        setIsLoading(false);
        hideLoadingButton();
      },
    });
  };

  const updateEstablishmentCertificates = async (id, data, onSuccess) => {
    setIsLoading(true);
    await sendRequest({
      method: 'patch',
      url: `/management/billing_info/${id}/upload_certificates/`,
      data,
      onSuccess: data => {
        onSuccess(data);
        setIsLoading(false);
      },
      onError: error => {
        showToast(
          'Error al actualizar',
          error?.data?.message || 'Error al actualizar los certificados',
        );
        setIsLoading(false);
        hideLoadingButton();
      },
      onFinish: () => {
        setIsLoading(false);
        hideLoadingButton();
      },
    });
  };

  return {
    isLoading,
    getEstablishmentsManagement,
    establishments,
    establishmentTicketHelp,
    getEstablishmentTicketHelp,
    updateEstablishmentBillingInformation,
    updateEstablishmentTicketHelp,
    getEstablishmentBillingInformation,
    saveEstablishmentBillingInformation,
    establishmentBillingInfo,
    updateEstablishmentCertificates,
    saveEstablishmentTicketHelp,
  };

};

export default useEstablishmentsManagement;
