import { useState } from 'react';
import useApi from 'hooks/useApi';

const useTickets = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [error, setError] = useState(null);

  const { sendRequest } = useApi();

  const getTickets = establishment => {
    setIsLoading(true);
    sendRequest({
      method: 'get',
      url: `/management/establishments/${establishment}/tickets`,
      onSuccess: response => setTickets(response),
      onError: () => setError('No se pudieron obtener los tickets'),
      onFinish: setIsLoading(false),
    });
  };

  return { getTickets, tickets, isLoading, error };

};

export default useTickets;
