export default [
  { label: 'RFC Receptor', field: 'rfc_receiver', sortable: true, textAlign: 'left', isImportant: true },
  { label: 'UUID', field: 'uuid', sortable: true, textAlign: 'left', isImportant: true },
  { label: 'Total', field: 'total_bill', sortable: true, isMoney: true, textAlign: 'left' },
  { label: 'Fecha Factura', field: 'date_format', sortable: true, textAlign: 'left' },
  { label: 'Folio', field: 'folio', sortable: true, textAlign: 'left' },
  { label: 'Status', field: 'status', sortable: true, textAlign: 'left' },
  { label: 'Archivos', field: 'files', textAlign: 'center' },
  { label: 'Detalle', field: 'options', textAlign: 'right', isImportant: true },
];
