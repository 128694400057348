import React from 'react';
import { Box, Flex, IconButton, Spinner, Text } from '@chakra-ui/react';
import { moneyFormat } from 'utils/money';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';

const Header = ({ data, onReload }) => {
  return (
    <Box mb={2}>
      {
        data ? (
          <Flex justifyContent='space-around' alignItems='center' p={4} bg='white' shadow='md' borderWidth='1px' borderRadius='lg'>
            <Box p={5} textAlign='center'>
              <Text fontSize='lg' color='blue.800' fontWeight='bold'>Cantidad de Facturas</Text>
              <Text fontSize='2xl' color='blue.800' fontWeight='semibold'>{data.invoice_count}</Text>
            </Box>
            <Box p={5} textAlign='center'>
              <Text fontSize='lg' color='blue.800' fontWeight='bold'>Importe</Text>
              <Text fontSize='2xl' color='blue.800' fontWeight='semibold'>{moneyFormat(data.subtotal)}</Text>
            </Box>
            <Box p={5} textAlign='center'>
              <Text fontSize='lg' color='blue.800' fontWeight='bold'>IVA</Text>
              <Text fontSize='2xl' color='blue.800' fontWeight='semibold'>{moneyFormat(data.iva)}</Text>
            </Box>
            <Box p={5} textAlign='center'>
              <Text fontSize='lg' color='blue.800' fontWeight='bold'>Total</Text>
              <Text fontSize='2xl' color='blue.800' fontWeight='semibold'>{moneyFormat(data.total)}</Text>
            </Box>
            <IconButton
              aria-label='Reload data'
              icon={<FontAwesomeIcon icon={faRedo} />}
              onClick={onReload}
              size='lg'
              colorScheme='blue'
              variant='ghost'
            />
          </Flex>
        ) : (
          <Spinner size='lg' color='blue.800' />
        )
      }
    </Box>
  );
};

export default Header;
