import React, { useEffect, useRef, useState } from 'react';
import { Box, FormControl, FormLabel, Input, Button, Heading, VStack, useToast, Select, FormErrorMessage, Grid, GridItem, Text, Spinner } from '@chakra-ui/react';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';
import { useEstablishmentsManagement, useGetState, useGetPropertiesForm } from 'hooks';
import { useGlobalContext } from 'context/globalContext';
import { getFormData, isEmptyObject } from 'utils/funGeneral';

const validationSchema = Yup.object({
  name: Yup.string().required('La razón social es obligatoria'),
  tax_payer_registry: Yup.string().required('El RFC es obligatorio'),
  street: Yup.string().required('La calle es obligatoria'),
  outdoor_number: Yup.string().required('El número exterior es obligatorio'),
  colony: Yup.string().required('La colonia es obligatoria'),
  location: Yup.string().required('La localidad es obligatoria'),
  municipality: Yup.string().required('El municipio es obligatorio'),
  state: Yup.string().required('El estado es obligatorio'),
  country: Yup.string().required('El país es obligatorio'),
  zip_code: Yup.string().required('El código postal es obligatorio'),
  concept: Yup.string().required('El concepto SAT es obligatorio'),
  description: Yup.string().required('La descripción es obligatoria'),
  folio: Yup.number().required('El folio es obligatorio'),
  serie: Yup.string().required('La serie es obligatoria'),
  regimen: Yup.string().required('El régimen es obligatorio'),
});

const COUNTRY_NAME = 'MEXICO';

const MainSettingsForm = () => {
  const toast = useToast();
  const establishmentInfoFormRef = useRef(null);
  const { isLoading, getEstablishmentBillingInformation, updateEstablishmentBillingInformation, saveEstablishmentBillingInformation, establishmentBillingInfo } = useEstablishmentsManagement();
  const { state: { establishment: { establishmentSelected } } } = useGlobalContext();
  const { listState } = useGetState();
  const { getValuesInitial, resetEstablishment, setFieldForm, setClearTouchableInitial } = useGetPropertiesForm();
  const [filePreview, setFilePreview] = useState(null);

  const [valuesInitial] = useState({
    name: '',
    tax_payer_registry: '',
    street: '',
    outdoor_number: '',
    interior_number: '',
    colony: '',
    location: '',
    municipality: '',
    state: '',
    country: COUNTRY_NAME,
    zip_code: '',
    concept: '',
    description: '',
    folio: '',
    serie: '',
    regimen: '',
  });

  const handleImageChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      setFieldValue('logo', file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setFilePreview(null);
    }
  };

  const handleFileRemove = () => {
    setFilePreview(null); // Limpiar el archivo seleccionado
    const fileInput = document.getElementById('logo');
    if (fileInput) {
      fileInput.value = '';
    }
  };

  const clearFormBilling = () => {
    handleFileRemove();
    resetEstablishment(establishmentInfoFormRef);
  };

  const setupForm = establishmentBillingInfo => {
    getValuesInitial(establishmentBillingInfo, establishmentInfoFormRef);
    if (!establishmentBillingInfo.logo) {
      setFilePreview(null);
    }
  };

  useEffect(() => {
    if (establishmentSelected) {
      getEstablishmentBillingInformation(establishmentSelected?.id);
    }
  }, [establishmentSelected]);

  useEffect(() => {
    if (establishmentSelected) {
      //Limpia el formulario
      clearFormBilling();
      //Se asigna country por default
      setFieldForm('country', COUNTRY_NAME, establishmentInfoFormRef);
      if (establishmentBillingInfo !== null && !isEmptyObject(establishmentBillingInfo)) {
        setupForm(establishmentBillingInfo);
      } else {
        getValuesInitial(valuesInitial, establishmentInfoFormRef, setClearTouchableInitial);
      }
    }
  }, [establishmentSelected, establishmentBillingInfo]);

  return (
    <Box bg='white' p={4} borderRadius='lg' boxShadow='sm' color='blue.800'>
      <Heading size='lg' mb={6}>Configuración General</Heading>
      <Formik
        innerRef={establishmentInfoFormRef}
        initialValues={valuesInitial}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          if (isEmptyObject(establishmentBillingInfo)) { //si el objeto es vacio se almacena por primera vez se realiza el guardado
            const data = { ...values, establishment: establishmentSelected?.id };
            const saveFormData = await getFormData(data);
            console.log(`data a enviar ${JSON.stringify(data)}`);
            await saveEstablishmentBillingInformation(
              saveFormData,
              response => {
                resetForm();
                handleFileRemove();
                setupForm(response);
                toast({
                  title: 'Configuración guardada',
                  description: 'Los datos de configuración han sido guardada correctamente.',
                  status: 'success',
                  duration: 5000,
                  isClosable: true,
                });
                setSubmitting(false);
              },
            );
          } else {
            const formDataInfoBill = await getFormData(values);
            await updateEstablishmentBillingInformation(
              establishmentSelected?.id,
              formDataInfoBill,
              response => {
                resetForm();
                handleFileRemove();
                setupForm(response);
                toast({
                  title: 'Configuración actualizada',
                  description: 'Los datos de configuración han sido actualizados correctamente.',
                  status: 'success',
                  duration: 5000,
                  isClosable: true,
                });
                setSubmitting(false);
              },
            );
          }
        }}
      >
        {({ handleSubmit, setFieldValue, isValid, handleBlur, handleChange, touched, isSubmitting, values, errors }) => (
          <Form>
            {isLoading ? (
              <Spinner size='lg' color='blue.800' />
            ) : (
              <VStack spacing={4}>
                <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={6}>
                  <GridItem colSpan={{ base: 1, md: 3 }}>
                    <Heading size='md'>Información Fiscal</Heading>
                  </GridItem>
                  <GridItem>
                    <FormControl isInvalid={touched.name && errors.name}>
                      <FormLabel>Razón Social</FormLabel>
                      <Field
                        as={Input}
                        id='name'
                        name='name'
                        placeholder='Razón Social'
                        onBlur={handleBlur('name')}
                        onChange={handleChange('name')}
                      />
                      <ErrorMessage component={FormErrorMessage} name='name' />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl isInvalid={touched.tax_payer_registry && errors.tax_payer_registry}>
                      <FormLabel>RFC</FormLabel>
                      <Field
                        as={Input}
                        id='tax_payer_registry'
                        name='tax_payer_registry'
                        placeholder='RFC'
                        onBlur={handleBlur('tax_payer_registry')}
                        onChange={handleChange('tax_payer_registry')}
                      />
                      <ErrorMessage component={FormErrorMessage} name='tax_payer_registry' />
                    </FormControl>
                  </GridItem>
                </Grid>
                <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={6}>
                  <GridItem colSpan={{ base: 1, md: 3 }}>
                    <Heading size='md'>Dirección Fiscal</Heading>
                  </GridItem>
                  <GridItem>
                    <FormControl isInvalid={touched.street && errors.street}>
                      <FormLabel>Calle</FormLabel>
                      <Field
                        as={Input}
                        id='street'
                        name='street'
                        placeholder='Calle'
                        onBlur={handleBlur('street')}
                        onChange={handleChange('street')}
                      />
                      <ErrorMessage component={FormErrorMessage} name='street' />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl isInvalid={touched.outdoor_number && errors.outdoor_number}>
                      <FormLabel>Número Exterior</FormLabel>
                      <Field
                        as={Input}
                        id='outdoor_number'
                        name='outdoor_number'
                        placeholder='Número Exterior'
                        onBlur={handleBlur('outdoor_number')}
                        onChange={handleChange('outdoor_number')}
                      />
                      <ErrorMessage component={FormErrorMessage} name='outdoor_number' />
                    </FormControl>
                  </GridItem>

                  <GridItem>
                    <FormControl>
                      <FormLabel>Número Interior (Opcional)</FormLabel>
                      <Field
                        as={Input}
                        id='interior_number'
                        name='interior_number'
                        placeholder='Número Interior'
                        onBlur={handleBlur('interior_number')}
                        onChange={handleChange('interior_number')}
                      />
                    </FormControl>
                  </GridItem>

                  <GridItem>
                    <FormControl isInvalid={touched.colony && errors.colony}>
                      <FormLabel>Colonia</FormLabel>
                      <Field
                        as={Input}
                        id='colony'
                        name='colony'
                        placeholder='Colonia'
                        onBlur={handleBlur('colony')}
                        onChange={handleChange('colony')}
                      />
                      <ErrorMessage component={FormErrorMessage} name='colony' />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl isInvalid={touched.location && errors.location}>
                      <FormLabel>Localidad</FormLabel>
                      <Field
                        as={Input}
                        id='location'
                        name='location'
                        placeholder='Localidad'
                        onBlur={handleBlur('location')}
                        onChange={handleChange('location')}
                      />
                      <ErrorMessage component={FormErrorMessage} name='location' />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl isInvalid={touched.municipality && errors.municipality}>
                      <FormLabel>Municipio</FormLabel>
                      <Field
                        as={Input}
                        id='municipality'
                        name='municipality'
                        placeholder='Municipio'
                        onBlur={handleBlur('municipality')}
                        onChange={handleChange('municipality')}
                      />
                      <ErrorMessage component={FormErrorMessage} name='municipality' />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl isInvalid={touched.state && errors.state}>
                      <FormLabel>Estado</FormLabel>
                      <Select
                        onBlur={handleBlur('state')}
                        onChange={e => {
                          setFieldValue('state', e?.target?.value);
                        }}
                        id='state'
                        name='state'
                        value={values.state}
                        placeholder='Selecciona un estado'
                      >
                        {listState?.map(state => (
                          <option key={state.id} value={state.name}>{state.name}</option>
                        ))}
                      </Select>
                      <ErrorMessage component={FormErrorMessage} name='state' />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl isInvalid={touched.zip_code && errors.zip_code}>
                      <FormLabel>Código Postal</FormLabel>
                      <Field
                        as={Input}
                        id='zip_code'
                        name='zip_code'
                        placeholder='Código Postal'
                        onBlur={handleBlur('zip_code')}
                        onChange={handleChange('zip_code')}
                      />
                      <ErrorMessage component={FormErrorMessage} name='zip_code' />
                    </FormControl>
                  </GridItem>
                </Grid>
                <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={6}>
                  <GridItem colSpan={{ base: 1, md: 3 }}>
                    <Heading size='md'>Información de la Factura</Heading>
                  </GridItem>
                  <GridItem>
                    <FormControl isInvalid={touched.concept && errors.concept}>
                      <FormLabel>Concepto SAT</FormLabel>
                      <Field
                        as={Input}
                        id='concept'
                        name='concept'
                        placeholder='Concepto SAT'
                        onBlur={handleBlur('concept')}
                        onChange={handleChange('concept')}
                      />
                      <ErrorMessage component={FormErrorMessage} name='concept' />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl isInvalid={touched.description && errors.description}>
                      <FormLabel>Descripción</FormLabel>
                      <Field
                        as={Input}
                        id='description'
                        name='description'
                        placeholder='Descripción'
                        onBlur={handleBlur('description')}
                        onChange={handleChange('description')}
                      />
                      <ErrorMessage component={FormErrorMessage} name='description' />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl isInvalid={touched.folio && errors.folio}>
                      <FormLabel>Folio</FormLabel>
                      <Field
                        as={Input}
                        type='number'
                        id='folio'
                        name='folio'
                        value={values.folio}
                        placeholder='Folio'
                        onBlur={handleBlur('folio')}
                        onChange={handleChange('folio')}
                      />
                      <ErrorMessage component={FormErrorMessage} name='folio' />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl isInvalid={touched.serie && errors.serie}>
                      <FormLabel>Serie</FormLabel>
                      <Field
                        as={Input}
                        id='serie'
                        name='serie'
                        placeholder='Serie'
                        onBlur={handleBlur('serie')}
                        onChange={handleChange('serie')}
                      />
                      <ErrorMessage component={FormErrorMessage} name='serie' />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel>Logo (Opcional)</FormLabel>
                      <Field name='logo'>
                        {({ field }) => (
                          <>
                            <input
                              type='file'
                              id='logo'
                              name='logo'
                              accept='image/*'
                              onChange={event => handleImageChange(event, setFieldValue)}
                              onBlur={handleBlur('logo')}
                            />
                          </>
                        )}
                      </Field>
                      {filePreview && (
                        <>
                          <img src={filePreview} alt='preview' style={{ maxHeight: '50px' }} />
                          <button type='button' onClick={handleFileRemove}>
                            <Text fontWeight='medium' fontSize='small' color='red.500'>Quitar archivo</Text>
                          </button>
                        </>
                      )}
                      <ErrorMessage component={FormErrorMessage} name='logo' />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl isInvalid={touched.regimen && errors.regimen}>
                      <FormLabel>Régimen Fiscal</FormLabel>
                      <Field
                        as={Input}
                        id='regimen'
                        name='regimen'
                        placeholder='Régimen Fiscal'
                        onBlur={handleBlur('regimen')}
                        onChange={handleChange('regimen')}
                      />
                      <ErrorMessage component={FormErrorMessage} name='regimen' />
                    </FormControl>
                  </GridItem>
                </Grid>
                <Button onClick={handleSubmit} mt={4} colorScheme='blue' isLoading={isSubmitting} width='full'>
                  Guardar Cambios
                </Button>
              </VStack>
            )}
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default MainSettingsForm;
