import React from 'react';
import { VStack, HStack, Button, Input, Link, Text, useToast, FormControl, FormLabel, FormErrorMessage, Center } from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileCode } from '@fortawesome/free-solid-svg-icons';
import useBilling from '../../hooks/useBilling';

const InvoiceActions = ({ invoice, invoiceResend, reset }) => {
  const toast = useToast();
  const { resendInvoice, isLoading } = useBilling();

  const xml = invoice === null ? invoiceResend.xml : invoice.url_xml;
  const pdf = invoice === null ? invoiceResend.pdf : invoice.url_pdf;
  const id = invoice === null ? invoiceResend.id : invoice.id;

  const emailSchema = Yup.object({
    email: Yup.string().email('Escribe un correo válido').required('Correo es necesario'),
  });

  const onSuccess = () => {
    toast({
      title: 'Factura reenviada',
      description: 'La factura ha sido reenviada',
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top',
    });
  };

  return (
    <div>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={emailSchema}
        onSubmit={(values, actions) => {
          resendInvoice({ email: values.email, invoice: id }, onSuccess);
          actions.setSubmitting(false);
        }}
      >
        {({ handleSubmit, errors, touched }) => (
          <VStack spacing={4} p={5} borderWidth='2px' borderRadius='lg' boxShadow='lg' backgroundColor='white' align='stretch'>
            {invoice === null && invoiceResend ? (
              <Text fontSize='md' color='red.500' mb={4}>
                El recibo ya se facturó y puede descargar desde aquí la factura.
              </Text>
            ) : null}
            <HStack justifyContent='space-between'>
              <Link href={xml} isExternal download>
                <Button leftIcon={<FontAwesomeIcon icon={faFileCode} />} colorScheme='blue' variant='solid'>
                  Descargar XML
                </Button>
              </Link>
              <Link href={pdf} isExternal download>
                <Button leftIcon={<FontAwesomeIcon icon={faFilePdf} />} colorScheme='blue' variant='solid'>
                  Descargar PDF
                </Button>
              </Link>
            </HStack>
            <Form>
              <FormControl isInvalid={errors.email && touched.email}>
                <FormLabel htmlFor='email'>Email para reenviar factura</FormLabel>
                <Field as={Input} id='email' name='email' type='email' color='black' placeholder='Introduce tu correo electrónico' />
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              </FormControl>
              <Button
                mt={4}
                colorScheme='teal'
                isLoading={isLoading}
                type='submit'
                onClick={() => handleSubmit()}
                disabled={isLoading}
              >
                Reenviar Factura
              </Button>
            </Form>
          </VStack>
        )}
      </Formik>
      <Center mt={4}>
        <Text cursor='pointer' onClick={reset}>
          Generar Nueva Factura
        </Text>
      </Center>
    </div>
  );
};

export default InvoiceActions;
