export const establishmentInitialState = {
  establishmentSelected: null,
  establishments: [],
};

export const establishmentReducer = (state, action) => {
  switch (action.type) {
    case 'SET_SELECTED_ESTABLISHMENT':
      return {
        ...state,
        establishmentSelected: action.payload,
      };
    case 'SET_ESTABLISHMENTS':
      return {
        ...state,
        establishments: action.payload,
      };
    default:
      return state;
  }
};
