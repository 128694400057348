import React from 'react';
import { Box, Text, Stack } from '@chakra-ui/react';

const TicketInfoCard = ({ selectedEstablishment, ticket }) => {
  return (
    <Box
      borderWidth='1px'
      borderRadius='lg'
      overflow='hidden'
      p={4}
      bg='rgba(255, 255, 255, 0.3)' // Transparent background
      boxShadow='md'
    >
      <Stack spacing={3}>
        <Box>
          <Text fontWeight='bold'>Establecimiento:</Text>
          <Text>{selectedEstablishment.name}</Text>
        </Box>
        <Box>
          <Text fontWeight='bold'>Código de Recibo/Boleto:</Text>
          <Text>{ticket.code}</Text>
        </Box>
        <Box>
          <Text fontWeight='bold'>Monto:</Text>
          <Text>{ticket.amount}</Text>
        </Box>
        <Box>
          <Text fontWeight='bold'>Impuestos:</Text>
          <Text>{ticket.taxes}</Text>
        </Box>
        <Box>
          <Text fontWeight='bold'>Total:</Text>
          <Text>{ticket.total}</Text>
        </Box>
      </Stack>
    </Box>
  );
};

export default TicketInfoCard;
