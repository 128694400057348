import { useState } from 'react';
import useApi from 'hooks/useApi';

const usePeriod = () => {

  const [periods, setPeriods] = useState([]);
  const [invoicesData, setInvoicesData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const { sendRequest } = useApi();

  const listPeriods = id => {
    setIsLoading(true);
    setError(null);
    sendRequest({
      method: 'get',
      url: `/management/period/list_periods/${id}`,
      onSuccess: response => setPeriods(response),
      onError: error => setError(error.response.data),
      onFinish: () => setIsLoading(false),
    });
  };

  const listInvoices = (establishment, period, endPoint) => {
    setIsLoading(true);
    setError(null);
    sendRequest({
      method: 'get',
      url: `/management/period/${endPoint}/${establishment}/${period}`,
      onSuccess: response => setInvoicesData(response),
      onError: error => setError(error.response.data),
      onFinish: () => setIsLoading(false),
    });
  };

  return {
    periods,
    invoicesData,
    listInvoices,
    listPeriods,
    isLoading,
    error,
  };

};

export default usePeriod;
