import React from 'react';
import {
  Box,
  VStack,
  Heading,
  Text,
  Divider, Button, HStack, useDisclosure, Flex, Badge,
} from '@chakra-ui/react';
import { moneyFormat } from 'utils/money';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import CancelModal from './CancelModal';
import ResendModal from './ResendModal';

const InvoiceDisplay = ({ invoice, id, handleRetrieveInvoice }) => {
  const { isOpen: isCancelOpen, onOpen: onCancelOpen, onClose: onCancelClose } = useDisclosure();
  const { isOpen: isResendOpen, onOpen: onResendOpen, onClose: onResendClose } = useDisclosure();

  return (
    <VStack spacing={4} align='stretch'>
      <Box p={4} bg='white' borderRadius='lg' shadow='md'>
        <Flex justify='space-between' align='center'>
          <Heading fontSize='xl' mb={2} color='blue.800'>Información General del Comprobante</Heading>
          {(invoice.cancellation_voucher || invoice.cancellation_request_id) && (
            <Badge colorScheme='red' fontSize='1.2em' p={2} borderRadius='md' fontWeight='bold'>
              Cancelado
            </Badge>
          )}
        </Flex>
        <Divider />
        <VStack alignItems='start' mt={3} spacing={1}>
          <Text color='blue.800'>
            <strong>UUID:</strong>
            {invoice.Comprobante.uuid}
          </Text>
          <Text color='blue.800'>
            <strong>Folio:</strong>
            {invoice.Comprobante.Folio}
          </Text>
          <Text color='blue.800'>
            <strong>Fecha de Facturación:</strong>
            {invoice.Comprobante.Fecha}
          </Text>
          <Text color='blue.800'>
            <strong>Forma de Pago:</strong>
            {invoice.Comprobante.FormaPago}
          </Text>
          <Text color='blue.800'>
            <strong>SubTotal:</strong>
            {moneyFormat(invoice.Comprobante.SubTotal)}
          </Text>
          <Text color='blue.800'>
            <strong>Total:</strong>
            {moneyFormat(invoice.Comprobante.Total)}
          </Text>
          <Text color='blue.800'>
            <strong>Método de Pago:</strong>
            {invoice.Comprobante.MetodoPago}
          </Text>
        </VStack>
        <HStack mt={4} spacing={3}>
          <Button colorScheme='red' onClick={onCancelOpen} leftIcon={<FontAwesomeIcon icon={faTimesCircle} />}>Cancelar</Button>
          <Button colorScheme='blue' onClick={onResendOpen} leftIcon={<FontAwesomeIcon icon={faPaperPlane} />}>Reenviar</Button>
        </HStack>
      </Box>

      <Box p={4} bg='white' borderRadius='lg' shadow='md'>
        <Heading fontSize='xl' mb={2} color='blue.800'>Datos del Emisor</Heading>
        <Divider />
        <VStack alignItems='start' mt={3} spacing={1}>
          <Text color='blue.800'>
            <strong>RFC:</strong>
            {invoice.Emisor.Rfc}
          </Text>
          <Text color='blue.800'>
            <strong>Nombre:</strong>
            {invoice.Emisor.Nombre}
          </Text>
          <Text color='blue.800'>
            <strong>Régimen Fiscal:</strong>
            {invoice.Emisor.RegimenFiscal}
          </Text>
        </VStack>
      </Box>

      <Box p={4} bg='white' borderRadius='lg' shadow='md'>
        <Heading fontSize='xl' mb={2} color='blue.800'>Datos del Receptor</Heading>
        <Divider />
        <VStack alignItems='start' mt={3} spacing={1}>
          <Text color='blue.800'>
            <strong>RFC:</strong>
            {invoice.Receptor.Rfc}
          </Text>
          <Text color='blue.800'>
            <strong>Nombre:</strong>
            {invoice.Receptor.Nombre}
          </Text>
          <Text color='blue.800'>
            <strong>Uso CFDI:</strong>
            {invoice.Receptor.UsoCFDI}
          </Text>
          <Text color='blue.800'>
            <strong>Régimen Fiscal del Receptor:</strong>
            {invoice.Receptor.RegimenFiscalReceptor}
          </Text>
          <Text color='blue.800'>
            <strong>Domicilio Fiscal del Receptor:</strong>
            {invoice.Receptor.DomicilioFiscalReceptor}
          </Text>
        </VStack>
      </Box>

      <Box p={4} bg='white' borderRadius='lg' shadow='md'>
        <Heading fontSize='xl' mb={2} color='blue.800'>Detalles de Conceptos</Heading>
        <Divider />
        <VStack alignItems='start' mt={3} spacing={1}>
          {invoice.Conceptos.map((concepto, index) => (
            <Box key={index} w='full'>
              <Text color='blue.800'>
                <strong>Descripción:</strong>
                {concepto.Descripcion}
              </Text>
              <Text color='blue.800'>
                <strong>Cantidad:</strong>
                {concepto.Cantidad}
              </Text>
              <Text color='blue.800'>
                <strong>Valor Unitario:</strong>
                {moneyFormat(concepto.ValorUnitario)}
              </Text>
              <Text color='blue.800'>
                <strong>Importe:</strong>
                {moneyFormat(concepto.Importe)}
              </Text>
              <Text color='blue.800'>
                <strong>Objeto de Impuesto:</strong>
                {concepto.ObjetoImp}
              </Text>
              <Text color='blue.800'>
                <strong>Clave ProdServ:</strong>
                {concepto.ClaveProdServ}
              </Text>
              <Text color='blue.800'>
                <strong>No. Identificación:</strong>
                {concepto.NoIdentificacion}
              </Text>
              <Text color='blue.800'>
                <strong>Clave Unidad:</strong>
                {concepto.ClaveUnidad}
              </Text>
              <Text color='blue.800'>
                <strong>Unidad:</strong>
                {concepto.Unidad}
              </Text>
              {concepto.Impuestos.map((impuesto, impIndex) => (
                <Box key={impIndex}>
                  <Text color='blue.800'>
                    <strong>Base Impuesto:</strong>
                    {moneyFormat(impuesto.Base)}
                  </Text>
                  <Text color='blue.800'>
                    <strong>Impuesto:</strong>
                    {impuesto.Impuesto}
                  </Text>
                  <Text color='blue.800'>
                    <strong>Tipo de Factor:</strong>
                    {impuesto.TipoFactor}
                  </Text>
                  <Text color='blue.800'>
                    <strong>Tasa o Cuota:</strong>
                    {impuesto.TasaOCuota}
                  </Text>
                  <Text color='blue.800'>
                    <strong>Importe de Impuesto:</strong>
                    {moneyFormat(impuesto.Importe)}
                  </Text>
                </Box>
              ))}
            </Box>
          ))}
        </VStack>
      </Box>

      <CancelModal isOpen={isCancelOpen} onClose={onCancelClose} id={id} handleRetrieveInvoice={handleRetrieveInvoice} />
      <ResendModal isOpen={isResendOpen} onClose={onResendClose} id={id} />
    </VStack>
  );
};

export default InvoiceDisplay;
