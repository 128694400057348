export const getFormData = async values => {
  const formData = new FormData();
  for (const key in values) {
    if (Object.prototype.hasOwnProperty.call(values, key)) {
      if (typeof values[key] === 'string' && values[key].trim() !== '') {
        formData.append(key, values[key].trim());
      } else {
        formData.append(key, values[key]);
      }
    }
  }

  return formData;
};

export const isEmptyObject = obj => {
  return Object.keys(obj).length === 0;
};
