import React, { useEffect } from 'react';
import { Box, Text, Button, IconButton } from '@chakra-ui/react';
import { CustomTable } from 'components/index';
import { faEye, faFileArchive } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import StatusIndicator from './StatusIndicator';
import { useGlobalContext } from 'context/globalContext';
import usePeriod from 'hooks/usePeriod';

const Invoices = () => {

  const { state: { establishment: { establishmentSelected } } } = useGlobalContext();

  const navigate = useNavigate();
  const { periods, listPeriods, isLoading, error } = usePeriod();

  useEffect(() => {
    if (establishmentSelected) {
      listPeriods(establishmentSelected.id);
    }
  }, [establishmentSelected]);

  const headers = [
    { label: 'Periodo', field: 'label', sortable: true },
    { label: 'Venta', field: 'sale', sortable: true, isMoney: true },
    { label: 'Facturado a Usuarios', field: 'billing', sortable: true, isMoney: true },
    { label: 'Público General', field: 'general_public', sortable: true, isMoney: true },
    { label: 'Estatus', field: 'status', sortable: true, isBold: true },
    { label: 'Acciones', field: 'actions', textAlign: 'right' },
  ];

  const enhancedData = periods.map(item => ({
    ...item,
    status: <StatusIndicator status={item.status} />,
    actions: (
      <Button
        leftIcon={<FontAwesomeIcon icon={faEye} />}
        colorScheme='blue'
        onClick={() => navigate(`/admin/facturacion/periodo/${item.id}`)}
      >
        Ver Detalle
      </Button>
    ),
  }));

  return (
    <Box width='full' overflowX='auto'>
      <Text fontSize='xl' mb={4} color='blue.800'>Periodos de Facturación</Text>
      <CustomTable headers={headers} data={enhancedData} />
    </Box>

  );
};

export default Invoices;
