import React, { useState, useEffect } from 'react';
import {
  Box, Input, Text, Spinner, Button, Link, IconButton,
} from '@chakra-ui/react';
import { CustomTable } from 'components';
import { useGlobalContext } from 'context/globalContext';
import useTickets from 'hooks/useTickets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';

const Tickets = () => {
  const { state: { establishment: { establishmentSelected } } } = useGlobalContext();
  const { getTickets, isLoading, tickets, error } = useTickets();

  const [filter, setFilter] = useState('');

  const listTickets = () => getTickets(establishmentSelected.id);

  useEffect(() => {
    if (establishmentSelected) {
      listTickets();
    }
  }, [establishmentSelected]);

  const headers = [
    { label: 'ID', field: 'id', sortable: true, textAlign: 'left', isImportant: true },
    { label: 'Código', field: 'code', sortable: true, textAlign: 'left', isImportant: true },
    { label: 'Fecha Transacción', field: 'checkout', sortable: true, textAlign: 'left' },
    { label: 'Monto', field: 'total', sortable: true, textAlign: 'left', isMoney: true },
    { label: 'Factura', field: 'invoice', sortable: true, textAlign: 'left' },
  ];

  const filteredTickets = tickets.map(ticket => ({
    ...ticket,
    invoice: ticket.invoice ? (
      <Link to={`/ticket/${ticket.id}`}>
        <IconButton
          icon={<FontAwesomeIcon icon={faFileInvoice} />}
          aria-label='Ver Detalles'
          colorScheme='green'
        />
      </Link>
    ) : null,
  })).filter(ticket => Object.values(ticket)
    .filter(value => typeof value === 'string')
    .some(value => value.toLowerCase().includes(filter.toLowerCase())));

  return (
    <Box width='full' overflowX='auto'>
      {isLoading ? (
        <>
          <Text>Cargando tickets...</Text>
          <Spinner color='white' />
        </>
      ) : error ? (
        <>
          <Text color='tomato'>{error}</Text>
          <Button colorScheme='teal' onClick={listTickets}>Reintentar</Button>
        </>
      ) : (
        <>
          <Input
            placeholder='Buscar...'
            value={filter}
            onChange={e => setFilter(e.target.value)}
            mb='4'
            color='blue.800'
          />
          <CustomTable headers={headers} data={filteredTickets} />
        </>
      )}
    </Box>
  );
};

export default Tickets;
