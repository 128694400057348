import { useState } from 'react';
import { useToast } from '@chakra-ui/react';
import useApi from './useApi';

const useBilling = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const toast = useToast();

  const { sendRequest } = useApi();

  const resendInvoice = (data, onSuccess) => {
    setIsLoading(true);
    setError(null);
    sendRequest({
      method: 'post',
      url: '/billing/resend_invoice/',
      data,
      onSuccess,
      onError: err => setError(err),
      onFinish: () => setIsLoading(false),
    });
  };

  const validTicket = (data, onSuccess) => {
    setIsLoading(true);
    setError(null);
    sendRequest({
      method: 'post',
      url: '/tickets/check_ticket/',
      data,
      onSuccess,
      onError: err => {
        setError(err);
        toast({
          title: 'Recibo no fue encontrado',
          description: 'Revisa la información del recibo.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      },
      onFinish: () => setIsLoading(false),
    });
  };

  const makeInvoice = (data, onSuccess) => {
    setIsLoading(true);
    setError(null);

    sendRequest({
      method: 'post',
      url: '/billing/make_invoice/',
      data,
      onSuccess,
      onError: err => {
        // Check if the error response has a status property
        if (err.response && err.response.status) {
          if (err.response.status === 404) {
            // Handle not found error specifically
            toast({
              title: 'Error',
              description: 'Número de recibo ya fue facturado',
              status: 'error',
              duration: 5000,
              isClosable: true,
              position: 'top',
            });
          } else {
            // Handle other types of errors
            toast({
              title: 'Error',
              description: err.response.data.receipt_id,
              status: 'error',
              duration: 10000,
              isClosable: true,
              position: 'top',
            });
          }
        } else {
          // Handle generic error if no response status is found
          toast({
            title: 'Error',
            description: 'Error desconocido',
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top',
          });
        }
      },
      onFinish: () => setIsLoading(false),
    });
  };

  return { validTicket, makeInvoice, resendInvoice, isLoading, error };

};

export default useBilling;
