import { useState } from 'react';
import constants from 'utils/constants';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from 'context/globalContext';
import useApi from './useApi';

const useSessionUser = () => {

  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const { sendRequest } = useApi();
  const navigate = useNavigate();
  const { dispatch } = useGlobalContext();

  const login = async (data, onSuccess) => {
    setIsLoading(true);
    setError(null);
    await sendRequest(
      {
        method: 'post',
        url: '/auth/login/',
        data,
        onSuccess: response => {
          const { data, success } = response;
          if (success) {
            if (onSuccess) {
              onSuccess(data);
            }
            setUser(data);
          }
          setIsLoading(false);
        },
        onError: err => {
          setError(err?.data?.message || 'Error al iniciar sesión');
          setIsLoading(false);
        },
        onFinish: () => {
          setIsLoading(false);
        },
      },
    );
  };

  const setSession = async (value, onError) => {
    try {
      await localStorage.setItem(constants.user.session, JSON.stringify(value));
    } catch (e) {
      onError(e);
    }
  };

  const removeSession = async () => {
    try {
      await localStorage.removeItem(constants.user.session);
    } catch (e) {}
  };

  const closeSession = async () => {
    console.log('Cerrar sesión');
    await removeSession();
    dispatch({
      type: 'LOGOUT',
      payload: null,
    });
    navigate('/login', { replace: true });
  };

  return { user, login, isLoading, error, setSession, removeSession, closeSession };
};

export default useSessionUser;
