import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const BarChartCustom = () => {

  const data = [
    { name: 'Enero', monto: 800 },
    { name: 'Febrero', monto: 900 },
    { name: 'Marzo', monto: 1200 },
    { name: 'Abril', monto: 750 },
    { name: 'Mayo', monto: 1200 },
    { name: 'Junio', monto: 1300 },
  ];

  return (
    <Box p={5} shadow='md' borderWidth='1px'>
      <Heading fontSize='xl' mb={12}>Monto Facturado Por Mes</Heading>
      <ResponsiveContainer width='100%' height={400}>
        <BarChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey='monto' fill='#0d47a1' />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default BarChartCustom;

