export const getFormatedDate = dateTimeString => {
  if (dateTimeString) {
    const now = new Date(dateTimeString);
    const day = now.getUTCDate().toString().padStart(2, '0');
    const month = (now.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = now.getFullYear();
    return `${year}/${month}/${day}`;
  }
  return null;

};
