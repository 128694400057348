import React, { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, FormControl, FormLabel, Input, Button, Text } from '@chakra-ui/react';
import useInvoice from 'hooks/useInvoice';

const ResendModal = ({ isOpen, onClose, id }) => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const { resendInvoice, isLoading } = useInvoice();

  const onSuccess = response => {
    setSuccessMessage('Factura enviada.');
    setEmail('');
  };

  const onError = () => {
    setErrorMessage('No se pudo enviar la factura');
  };

  const handleResend = () => {
    setErrorMessage('');
    setSuccessMessage('');
    resendInvoice(id, { email }, onSuccess, onError);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color='blue.800'>Reenviar Comprobante</ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel color='blue.800'>Correo Electrónico</FormLabel>
            <Input
              type='email'
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder='Ingresa el correo electrónico'
              color='blue.800'
            />
          </FormControl>
          {errorMessage && <Text color='red.500' mt={2}>{errorMessage}</Text>}
          {successMessage && <Text color='green.500' mt={2}>{successMessage}</Text>}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme='blue' onClick={handleResend} mr={2} isLoading={isLoading}>
            Reenviar
          </Button>
          <Button variant='ghost' onClick={onClose} disabled={isLoading}>
            Cerrar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ResendModal;
