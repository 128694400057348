import React from 'react';
import { Box, Flex, Grid, GridItem, Heading, Text } from '@chakra-ui/react';
import BarChartCustom from 'pages/Dashboard/BarChartCustom';

function Dashboard() {

  return (
    <Box p={5}>
      <Grid spacing={2} mb={10}>
        <GridItem>
          <Heading color='black' fontSize='xl' mb={4}>Dashboard</Heading>
        </GridItem>
      </Grid>
      <Grid
        templateColumns={{ base: '1fr', md: '1fr 1fr' }} // Adjusts layout based on the viewport
        gap={6}
      >
        <GridItem>
          <Box bg='white' borderRadius='lg' boxShadow='sm' color='blue.800'>
            <BarChartCustom />
          </Box>
        </GridItem>
        <GridItem>
          <Box borderRadius='lg' boxShadow='sm' color='blue.800'>
            <Box bg='white' p={5} mb={5}>
              <Heading fontSize='xl' mb={4}>Factura publico en general</Heading>
            </Box>
            <Flex justifyContent='space-around' p={4} bg='white' shadow='md' borderWidth='1px'>
              <Box bg='white' p={5} textAlign='center'>
                <Text fontSize='lg' color='blue.800' fontWeight='bold'>Total de Facturas Generadas</Text>
                <Text fontSize='2xl' color='blue.800' fontWeight='semibold'>200</Text>
              </Box>
              <Box bg='white' p={5} textAlign='center'>
                <Text fontSize='lg' color='blue.800' fontWeight='bold'>Monto Total Faturado</Text>
                <Text fontSize='2xl' color='blue.800' fontWeight='semibold'>$20890.00</Text>
              </Box>
            </Flex>
          </Box>
        </GridItem>
      </Grid>
    </Box>

  );
}

export default Dashboard;
