import React from 'react';
import { Flex, IconButton, Text, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUser, faUserCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useSessionUser } from 'hooks';
import { useGlobalContext } from 'context/globalContext';

const Topbar = ({ onOpen }) => {

  const { state: { establishment: { establishmentSelected } } } = useGlobalContext();
  const { closeSession } = useSessionUser();

  return (
    <Flex
      as='nav'
      align='center'
      justify='space-between'
      w='full'
      px={4}
      bg='blue.800'
      color='white'
      h='14'
    >
      <IconButton
        icon={<FontAwesomeIcon icon={faBars} />}
        onClick={onOpen}
        aria-label='open menu'
        variant='ghost'
        size='sm'
        color='white'
      />
      {
        establishmentSelected ? (
          <Text fontSize='lg' fontWeight='bold'>
            {establishmentSelected.name}
          </Text>
        ) : null
      }
      <Menu>
        <MenuButton as={IconButton} icon={<FontAwesomeIcon icon={faUser} />} variant='ghost' aria-label='User menu' color='white' />
        <MenuList bgColor='blue.800'>
          <MenuItem
            icon={<FontAwesomeIcon icon={faSignOutAlt} />}
            onClick={async () => {
              console.log('Cerrar sesión');
              await closeSession();
            }}
            _hover={{ bg: 'blue.700' }}
            bgColor='blue.800'
          >
            Cerrar Sesión
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default Topbar;
