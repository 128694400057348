import React from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  Button, FormControl, FormLabel, Input, FormErrorMessage, Box, Select, Grid, Text, Center,
} from '@chakra-ui/react';

import useBilling from '../../hooks/useBilling';

const tax_regimeOptions = [
  { value: '601', label: 'General de Ley Personas Morales' },
  { value: '612', label: 'Personas Físicas con Actividades Empresariales y Profesionales' },
  { value: '626', label: 'Régimen Simplificado de Confianza' },
  { value: '621', label: 'Incorporación Fiscal' },
  { value: '606', label: 'Arrendamiento' },
  { value: '603', label: 'Personas Morales con Fines no Lucrativos' },
  { value: '616', label: 'Sin obligaciones fiscales' },
  { value: '605', label: 'Sueldos y Salarios e Ingresos Asimilados a Salarios' },
  { value: '625', label: 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas' },
  { value: '624', label: 'Coordinados' },
  { value: '622', label: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras' },
  { value: '607', label: 'Régimen de Enajenación o Adquisición de Bienes' },
  { value: '608', label: 'Demás ingresos' },
  { value: '610', label: 'Residentes en el Extranjero sin Establecimiento Permanente en México' },
  { value: '611', label: 'Ingresos por Dividendos (socios y accionistas)' },
  { value: '614', label: 'Ingresos por intereses' },
  { value: '615', label: 'Régimen de los ingresos por obtención de premios' },
  { value: '620', label: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos' },
  { value: '623', label: 'Opcional para Grupos de Sociedades' },
];

const BillingForm = ({ selectedEstablishment, ticket, handleInvoice, reset }) => {
  const initialValues = {
    rfc: '',
    business_name: '',
    postal_code: '',
    email: '',
    tax_regime: '',
    use_cfdi: 'G03',
    receipt_id: ticket.code,
    establishment: selectedEstablishment.id,
    total: ticket.total,
    payment_method: ticket.payment_method === 'e' ? 'e' : 'c',
  };

  const validationSchema = Yup.object({
    rfc: Yup.string()
      .matches(/^([A-ZÑ&]{3,4}) ?(?:-?(\d{2})(\d{2})(\d{2})) ?(?:-?([A-Z\d]{3}))?$/, 'El RFC no es válido')
      .required('El RFC es obligatorio'),
    business_name: Yup.string()
      .required('La razón social es obligatoria'),
    postal_code: Yup.string()
      .matches(/^\d{5}$/, 'El código postal debe tener 5 dígitos')
      .required('El código postal es obligatorio'),
    email: Yup.string()
      .email('El correo no es válido')
      .required('El correo es obligatorio'),
    tax_regime: Yup.string()
      .required('El régimen fiscal es obligatorio'),
    use_cfdi: Yup.string()
      .required('El uso de CFDI es obligatorio'),
  });

  const { makeInvoice, isLoading } = useBilling();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        makeInvoice(values, data => handleInvoice(data));
        setSubmitting(false);
      }}
    >
      {({ values, isSubmitting, errors, touched, getFieldProps, isValid }) => (
        <Box pt={8}>
          <Form>
            <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={4}>
              <FormControl isInvalid={errors.rfc && touched.rfc}>
                <FormLabel htmlFor='rfc'>RFC</FormLabel>
                <Input id='rfc' {...getFieldProps('rfc')} />
                <FormErrorMessage>
                  <ErrorMessage name='rfc' />
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.business_name && touched.business_name}>
                <FormLabel htmlFor='business_name'>Razón Social</FormLabel>
                <Input id='business_name' {...getFieldProps('business_name')} />
                <FormErrorMessage>
                  <ErrorMessage name='business_name' />
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.postal_code && touched.postal_code}>
                <FormLabel htmlFor='postal_code'>Código Postal</FormLabel>
                <Input id='postal_code' {...getFieldProps('postal_code')} />
                <FormErrorMessage>
                  <ErrorMessage name='postal_code' />
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.email && touched.email}>
                <FormLabel htmlFor='email'>Correo</FormLabel>
                <Input id='email' {...getFieldProps('email')} />
                <FormErrorMessage>
                  <ErrorMessage name='email' />
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.tax_regime && touched.tax_regime}>
                <FormLabel htmlFor='tax_regime'>Régimen Fiscal</FormLabel>
                <Select
                  id='tax_regime'
                  {...getFieldProps('tax_regime')}
                  color='black'
                  sx={{
                    option: {
                      color: 'black',
                    }
                  }}
                >
                  <option value=''>Selecciona una opción</option>
                  {tax_regimeOptions.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                  ))}
                </Select>
                <FormErrorMessage>
                  <ErrorMessage name='tax_regime' />
                </FormErrorMessage>
              </FormControl>

              {values.payment_method !== 'e' && (
                <FormControl isInvalid={errors.payment_method && touched.payment_method}>
                  <FormLabel htmlFor='payment_method'>Método de pago</FormLabel>
                  <Select
                    id='payment_method'
                    {...getFieldProps('payment_method')}
                    color='black'
                    sx={{
                      option: {
                        color: 'black',
                      }
                    }}
                  >
                    <option value='c'>Tarjeta de crédito</option>
                    <option value='d'>Tarjeta de débito</option>
                  </Select>
                  <FormErrorMessage>
                    <ErrorMessage name='payment_method' />
                  </FormErrorMessage>
                </FormControl>
              )}

              <FormControl isInvalid={errors.use_cfdi && touched.use_cfdi}>
                <FormLabel htmlFor='use_cfdi'>Uso de CFDI</FormLabel>
                <Select
                  id='use_cfdi' {...getFieldProps('use_cfdi')}
                  color='black'
                  sx={{
                    option: {
                      color: 'black',
                    }
                  }}
                >
                  <option value='G03'>Gastos en General</option>
                  <option value='S01'>Sin efectos fiscales</option>
                </Select>
                <FormErrorMessage>
                  <ErrorMessage name='use_cfdi' />
                </FormErrorMessage>
              </FormControl>
            </Grid>

            <Button
              mt={4}
              width='full'
              colorScheme='teal'
              type='submit'
              isLoading={isLoading}
              disabled={isLoading || !isValid}
            >
              Facturar
            </Button>
            <Center mt={4}>
              <Text
                color='red.500'
                cursor='pointer'
                onClick={reset}
                textAlign='center'
              >
                Cancelar
              </Text>
            </Center>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

export default BillingForm;
