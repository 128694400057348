import React, { useEffect } from 'react';
import './App.css';
import { useGlobalContext } from 'context/globalContext';
import { BrowserRouter } from 'react-router-dom';
import constants from 'utils/constants';
import Router from './routes';

function App() {

  const { dispatch } = useGlobalContext();

  useEffect(() => {
    const userLogged = localStorage.getItem(constants.user.session);
    if (userLogged) {
      const userLoggedParse = JSON.parse(userLogged);
      dispatch({
        type: 'LOGIN',
        payload: userLoggedParse,
      });
    } else {
      dispatch({
        type: 'SET_IS_LOADING',
        payload: false,
      });
    }
  }, []);

  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
}

export default App;
