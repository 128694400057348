import React from 'react';
import { Link as ChakraLink, Icon } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SidebarLink = ({ to, icon, label, onClose }) => (
  <ChakraLink as={NavLink} to={to} p='2' _hover={{ bg: 'blue.700' }}>
    <Icon as={FontAwesomeIcon} icon={icon} mr={2} />
    {label}
  </ChakraLink>
);

export default SidebarLink;
