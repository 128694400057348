import React from 'react';
import { Button, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, VStack, Text, Box, Icon } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStore } from '@fortawesome/free-solid-svg-icons';

const EstablishmentsMenu = ({ establishments, handleSelectEstablishment }) => (
  <AccordionItem>
    <AccordionButton _expanded={{ bg: 'blue.700' }} p='2'>
      <Box flex='1' textAlign='left' color='white'>
        <Icon as={FontAwesomeIcon} icon={faStore} mr={2} />
        Establecimientos
      </Box>
      <AccordionIcon color='white' />
    </AccordionButton>
    <AccordionPanel pb={4} color='white'>
      <VStack spacing={2} align='stretch'>
        {establishments.length > 0 ? (
          establishments.map(establishment => (
            <Button
              key={establishment.id}
              onClick={() => handleSelectEstablishment(establishment)}
              variant='ghost'
              justifyContent='start'
              w='full'
              _hover={{ bg: 'blue.700' }}
              colorScheme='whiteAlpha' // Makes text and icon white
            >
              {establishment.name}
            </Button>
          ))
        ) : <Text>No hay establecimientos.</Text>}
      </VStack>
    </AccordionPanel>
  </AccordionItem>
);

export default EstablishmentsMenu;
