import React, { useState, useEffect } from 'react';
import { Box, Input, Text, Spinner, IconButton } from '@chakra-ui/react';
import { CustomTable } from 'components';
import { useGlobalContext } from 'context/globalContext';
import { getFormatedDate } from 'utils/time';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InvoiceButton from 'pages/Billing/InvoiceButton';
import { faFileCode, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import Header from 'pages/Period/InvoicesUser/header';
import usePeriod from 'hooks/usePeriod';
import headers from './headers';

const Invoices = ({ period }) => {

  const { state: { establishment: { establishmentSelected } } } = useGlobalContext();

  const [filter, setFilter] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const { listInvoices, isLoading, invoicesData } = usePeriod();

  const handleDownload = async (file, type) => {
    const a = document.createElement('a');
    a.href = `https://facturacion.driveapp.mx/api${file}`;
    a.download = `Factura.${type}`;
    a.target = '_blank';
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleReload = () => listInvoices(establishmentSelected.id, period, 'list_invoices');

  useEffect(() => {
    if (establishmentSelected) {
      handleReload();
    }
  }, [establishmentSelected]);

  useEffect(() => {
    if (invoicesData) {
      setFilteredData(invoicesData.invoices.map(invoice => ({
        ...invoice,
        date_format: getFormatedDate(invoice?.created_at),
        options: <InvoiceButton id={invoice.id} />,
        files: (
          <>
            {invoice?.xml && <IconButton onClick={() => handleDownload(invoice.xml, 'xml')} icon={<FontAwesomeIcon icon={faFileCode} />} colorScheme='blue' aria-label='Descargar XML' />}
            {invoice?.pdf && <IconButton onClick={() => handleDownload(invoice.pdf, 'pdf')} icon={<FontAwesomeIcon icon={faFilePdf} />} colorScheme='red' aria-label='Descargar PDF' ml={2} />}
          </>
        ),
        status: invoice?.cancellation_voucher !== null || invoice?.cancellation_request_id !== null ?
          <Text fontSize='small' fontWeight='bold' color='red.500'>CANCELADO</Text> :
          <Text fontSize='small' fontWeight='bold' color='green.500'>ACTIVA</Text>,
      })));
    }
  }, [invoicesData]);

  return (
    <Box width='full' overflowX='auto'>
      {isLoading ? (
        <Spinner color='blue.800' />
      ) : (
        <>
          <Header data={invoicesData} onReload={handleReload} />
          <Input
            placeholder='Buscar...'
            value={filter}
            onChange={e => setFilter(e.target.value)}
            mb='4'
            color='blue.800'
          />
          <CustomTable headers={headers} data={filteredData} />
        </>
      )}
    </Box>
  );
};

export default Invoices;

