import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from 'pages/HomePage';
import Login from 'pages/Login';
import Dashboard from 'pages/Dashboard';
import Invoices from 'pages/Billing';
import Layout from 'components/layout';
import InvoiceDetails from 'pages/InoviceDetails';
import SettingsPage from 'pages/Settings';
import Tickets from 'pages/Tickets';
import Period from 'pages/Period';
import ProtectedRoute from './ProtectedRoute';

const Router = () => {

  return (
    <Routes>
      <Route path='/' element={<HomePage />} />
      <Route path='login' element={<Login />} />
      <Route
        path='admin'
        element={(
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        )}
      >
        <Route path='panel' element={<Dashboard />} />
        <Route path='tickets' element={<Tickets />} />
        <Route path='facturacion' element={<Invoices />} />
        <Route path='facturacion/periodo/:id' element={<Period />} />
        <Route path='facturas/:id' element={<InvoiceDetails />} />
        <Route path='configuracion' element={<SettingsPage />} />
      </Route>
    </Routes>

  );
};

export default Router;
