import React, { useState, useEffect } from 'react';
import usePeriod from 'hooks/usePeriod';
import { useGlobalContext } from 'context/globalContext';
import CustomTable from 'components/CustomTable';
import { Box, Spinner, Input, Text, IconButton, Heading } from '@chakra-ui/react';
import { getFormatedDate } from 'utils/time';
import InvoiceButton from 'pages/Billing/InvoiceButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCode, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import headersInvoices from './headersInvoices';
import Header from './header';
import headers from './headers';

const GeneralPublic = ({ period }) => {
  const { state: { establishment: { establishmentSelected } } } = useGlobalContext();
  const { listInvoices, isLoading, error, invoicesData } = usePeriod();

  const [filter, setFilter] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const handleReload = () => {
    listInvoices(establishmentSelected.id, period, 'list_general_public');
  };

  useEffect(() => {
    if (establishmentSelected) {
      handleReload();
    }
  }, [establishmentSelected]);

  useEffect(() => {
    if (invoicesData) {
      const filtered = invoicesData.tickets.filter(ticket => Object.values(ticket).some(value => (value ? value.toString().toLowerCase().includes(filter.toLowerCase()) : false)));
      setFilteredData(filtered);
    }
  }, [filter, invoicesData]);

  const handleDownload = async (file, type) => {
    const a = document.createElement('a');
    a.href = `https://facturacion.driveapp.mx/api${file}`;
    a.download = `Factura.${type}`;
    a.target = '_blank';
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Box>
      {isLoading ? (
        <Spinner size='lg' color='blue.800' />
      ) : invoicesData ? (
        <>
          <Header data={invoicesData} onReload={handleReload} />
          {
            invoicesData.invoices && invoicesData.invoices.length > 0 ? (
              <>
                <Heading as='h2' size='xl' mt={4} color='blue.800'>Facturas</Heading>
                <CustomTable
                  headers={headersInvoices}
                  data={invoicesData.invoices.map(invoice => ({
                    ...invoice,
                    date_format: getFormatedDate(invoice?.created_at),
                    options: <InvoiceButton id={invoice.id} />,
                    files: (
                      <>
                        {invoice?.xml && <IconButton onClick={() => handleDownload(invoice.xml, 'xml')} icon={<FontAwesomeIcon icon={faFileCode} />} colorScheme='blue' aria-label='Descargar XML' />}
                        {invoice?.pdf && <IconButton onClick={() => handleDownload(invoice.pdf, 'pdf')} icon={<FontAwesomeIcon icon={faFilePdf} />} colorScheme='red' aria-label='Descargar PDF' ml={2} />}
                      </>
                    ),
                    status: invoice?.cancellation_voucher !== null || invoice?.cancellation_request_id !== null ?
                      <Text fontSize='small' fontWeight='bold' color='red.500'>CANCELADO</Text> :
                      <Text fontSize='small' fontWeight='bold' color='green.500'>ACTIVA</Text>,
                  }))}
                />
              </>
            ) : null
          }
          <Heading as='h2' size='xl' mt={4} color='blue.800'>Tickets</Heading>
          <Input
            placeholder='Buscar...'
            value={filter}
            onChange={e => setFilter(e.target.value)}
            mb={4}
            color='blue.800'
            mt={2}
          />
          <CustomTable data={filteredData} headers={headers} />
        </>
      ) : error ? (
        <Text color='tomato'>{error}</Text>
      ) : null}
    </Box>
  );
};

export default GeneralPublic;
