import React from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  Button, FormControl, FormLabel, Input, FormErrorMessage, Box, Text,
  useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader,
  ModalBody, Image, CloseButton, Center,
} from '@chakra-ui/react';
import useBilling from '../../hooks/useBilling';

function EstablishmentForm({ selectedEstablishment, handleTicket, reset }) {
  const initialValues = {
    code: '',
    total: '',
    establishment: selectedEstablishment.id,
  };

  const validationSchema = Yup.object({
    code: Yup.string()
      .required('El código es obligatorio')
      .matches(/^[a-zA-Z0-9]*$/, 'El código debe ser alfanumérico')
      .max(selectedEstablishment?.code_length, `El código debe tener máximo ${selectedEstablishment?.code_length} caracteres`),
    total: Yup.number()
      .typeError('El monto debe ser un número')
      .positive('El monto debe ser un número positivo')
      .required('El monto es obligatorio')
      .moreThan(0, 'El monto debe ser mayor a 0'),
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { validTicket, isLoading } = useBilling();

  return (
    <>
      {
        selectedEstablishment.logo_url ? (
          <Box align='center' marginBottom='4'>
            <Image src={selectedEstablishment.logo_url} alt='Establecimiento Logo' boxSize='150px' objectFit='contain' />
          </Box>
        ) : null
      }
      <Text
        mt={2}
        color='white'
        cursor='pointer'
        onClick={onOpen}
        textAlign='center'
      >
        ¿Dónde encuentro mi recibo / boleto?
      </Text>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={values => {
          validTicket(values, handleTicket);
        }}
      >
        {({ errors, touched, getFieldProps, isValid }) => (
          <Box pt={8}>
            <Form>
              <FormControl isInvalid={errors.code && touched.code}>
                <FormLabel htmlFor='code' color='white'>Número de recibo / boleto</FormLabel>
                <Input id='code' {...getFieldProps('code')} />
                <FormErrorMessage>
                  <ErrorMessage name='code' />
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.total && touched.total} mt={4}>
                <FormLabel htmlFor='total' color='white'>Monto Total</FormLabel>
                <Input id='total' {...getFieldProps('total')} type='number' />
                <FormErrorMessage>
                  <ErrorMessage name='total' />
                </FormErrorMessage>
              </FormControl>
              <Button
                mt={4}
                width='full'
                colorScheme='teal'
                type='submit'
                isLoading={isLoading}
                disabled={isLoading || !isValid}
              >
                Enviar
              </Button>
              <Center mt={4}>
                <Text
                  color='red.500'
                  cursor='pointer'
                  onClick={reset}
                  textAlign='center'
                >
                  Cancelar
                </Text>
              </Center>
            </Form>
          </Box>
        )}
      </Formik>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Instrucciones de Facturación</ModalHeader>
          <CloseButton position='absolute' right='8px' top='8px' color='red.500' onClick={onClose} />
          <ModalBody>
            <Text fontSize='md' mb={2} fontWeight='normal' color='gray.800'>{selectedEstablishment.label}</Text>
            <Text fontSize='sm' color='gray.600'>{selectedEstablishment.description}</Text>
            <Image src={selectedEstablishment.url} alt='Imagen de ayuda' maxW='100%' />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default EstablishmentForm;
