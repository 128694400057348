import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useGlobalContext } from 'context/globalContext';

function ProtectedRoute({ children }) {
  const { state: { auth: { isAuthenticated, isLoading } } } = useGlobalContext();
  const location = useLocation();

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  return isAuthenticated ? children : (
    <Navigate to='/login' state={{ from: location }} replace />
  );
}

export default ProtectedRoute;
