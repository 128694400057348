import React from 'react';
import { Box, Text, Link } from '@chakra-ui/react';

const Footer = () => {
  const email = 'facturacion@driveapp.mx';
  const subject = encodeURIComponent('Necesito ayuda en el sistema de facturación web');
  const body = encodeURIComponent('¡Hola!,\n\nMe gustaría recibir asistencia con el sistema de facturación web.\n\nMi duda es ');

  const mailtoLink = `mailto:${email}?subject=${subject}&body=${body}`;

  return (
    <Box
      as='footer'
      width='full'
      py={4}
      bg='brand.900'
      color='white'
      textAlign='center'
      mt={8}
    >
      <Link href={mailtoLink} color='teal.200'>
        <Text fontSize='sm'>
          ¿Necesitas ayuda?
        </Text>
      </Link>
      <Text>
        &copy;
        {' '}
        {new Date().getFullYear()}
        {' '}
        DriveApp. Todos los derechos reservados.
      </Text>
    </Box>
  );
};

export default Footer;
