import React, { useEffect, useRef } from 'react';
import {
  Box, Button, FormControl, FormLabel, Input, Heading, useToast, InputGroup, InputRightElement, IconButton, FormErrorMessage,
  Grid,
} from '@chakra-ui/react';
import { Formik, ErrorMessage, Form, Field } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useEstablishmentsManagement, useGetPropertiesForm } from 'hooks/index';
import { useGlobalContext } from 'context/globalContext';
import { getFormData } from 'utils/funGeneral';

const validationSchema = Yup.object({
  key_pem: Yup.mixed().required('El archivo .key es obligatorio'),
  cer_pem: Yup.mixed().required('El archivo .cer es obligatorio'),
  password: Yup.string().required('La contraseña es obligatoria'),
});

const CertificateUploadForm = () => {
  const toast = useToast();
  const [show, setShow] = React.useState(false);
  const handleClickShowPassword = () => setShow(!show);
  const { resetEstablishment } = useGetPropertiesForm();
  const { updateEstablishmentCertificates } = useEstablishmentsManagement();
  const { state: { establishment: { establishmentSelected } } } = useGlobalContext();

  const establishmentCertificateFormRef = useRef(null);

  const clearFormCer = () => {
    //SE RESTABLECE EL VALOR DEL INPUT FILE CADA QUE SE CAMBIA EL ESTADO
    const fileInputCert = document.getElementById('cer_pem');
    if (fileInputCert) {
      fileInputCert.value = '';
    }
    const fileInputkey = document.getElementById('key_pem');
    if (fileInputkey) {
      fileInputkey.value = '';
    }
  };

  useEffect(() => {
    if (establishmentSelected) {
      //SE RESETEA EL FORM PARA OBTENER LOS NUEVOS VALORES
      resetEstablishment(establishmentCertificateFormRef);
      clearFormCer();
    }
  }, [establishmentSelected]);

  return (
    <Box bg='white' p={4} borderRadius='lg' boxShadow='sm' color='blue.800'>
      <Heading size='lg' mb={6}>Subir Certificados Digitales</Heading>
      <Formik
        innerRef={establishmentCertificateFormRef}
        initialValues={{
          key_pem: undefined,
          cer_pem: undefined,
          password: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const formDataCertificate = await getFormData(values);
          await updateEstablishmentCertificates(
            establishmentSelected?.id,
            formDataCertificate,
            () => {
              resetForm();
              clearFormCer();
              toast({
                title: 'Certificados actualizados',
                description: 'Los archivos han sido actualizados correctamente.',
                status: 'success',
                duration: 5000,
                isClosable: true,
              });
            },
          );
          setSubmitting(false);
        }}
      >
        {formikProps => (
          <Form>
            <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={6}>
              <FormControl isInvalid={formikProps.touched.cer_pem && formikProps.errors.cer_pem}>
                <FormLabel>Archivo .cer</FormLabel>
                <Input
                  type='file'
                  id='cer_pem'
                  name='cer_pem'
                  onChange={event => {
                    formikProps.setFieldValue('cer_pem', event.currentTarget.files[0]);
                  }}
                  accept='.cer'
                />
                <ErrorMessage component={FormErrorMessage} name='cer_pem' />
              </FormControl>
              <FormControl isInvalid={formikProps.touched.key_pem && formikProps.errors.key_pem}>
                <FormLabel>Archivo .key</FormLabel>
                <Input
                  type='file'
                  id='key_pem'
                  name='key_pem'
                  onChange={event => {
                    formikProps.setFieldValue('key_pem', event.currentTarget.files[0]);
                  }}
                  accept='.key'
                />
                <ErrorMessage component={FormErrorMessage} name='key_pem' />
              </FormControl>
            </Grid>
            <FormControl isInvalid={formikProps.touched.password && formikProps.errors.password} mt={4}>
              <FormLabel>Contraseña</FormLabel>
              <InputGroup>
                <Field
                  as={Input}
                  id='password'
                  name='password'
                  onBlur={formikProps.handleBlur('password')}
                  onChange={formikProps.handleChange('password')}
                  type={show ? 'text' : 'password'}
                  placeholder='Contraseña'
                />
                <InputRightElement width='4.5rem'>
                  <IconButton
                    h='1.75rem'
                    size='sm'
                    onClick={() => handleClickShowPassword()}
                    icon={<FontAwesomeIcon icon={show ? faEyeSlash : faEye} />}
                  />
                </InputRightElement>
              </InputGroup>
              <ErrorMessage component={FormErrorMessage} name='password' />
            </FormControl>
            <Button onClick={formikProps.handleSubmit} mt={4} colorScheme='blue' isLoading={formikProps.isSubmitting} type='submit' width='full'>
              Guardar Certificados
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default CertificateUploadForm;
